import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VToolbar,{staticClass:"d-flex flex-row-reverse pb-5",attrs:{"flat":"","color":"rgba(0,0,0,0)"}},[_c(VBtn,{staticClass:"mr-3",attrs:{"color":"primary","dark":"","title":"Remover Filtros"},on:{"click":_vm.removeFiltros}},[_c(VIcon,[_vm._v("mdi-filter-remove")])],1),_c('HeaderControl',{attrs:{"headers":_vm.headersOptions,"local-storage-save-name":_vm.localStorageSaveName},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"dark":""}},[_vm._v(" mdi-tray-arrow-down ")])],1)]}}])},[_c(VList,{staticClass:"py-0",attrs:{"dense":""}},[_c(VListItem,{attrs:{"dense":""},on:{"click":function($event){return _vm.downloadReport('excel')}}},[_c(VListItemIcon,{staticClass:"mx-0"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-microsoft-excel")])],1),_c(VListItemTitle,[_vm._v("Excel")])],1)],1)],1),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.novoLancamento}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1),_c('custom-snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }