<template>
<div>
  <custom-snackbar ref="snackbar" />
  <ActionsComponent
      v-model="headers"
      :filtros="filtros"
      :headers-options="headersOptions"
      relatorio-excel="SolicitacoesFretes"
      excel-pdf
      local-storage-save-name="relatorio-solicitacao-frete"
  />
  <FilterComponent v-model="filtros"/>
  <ListComponent :filtros="filtros" :headers="headers" :reload-data.sync="reloadData"/>
</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ActionsComponent from './components/ActionsComponent.vue'
import FilterComponent from './components/FilterComponent.vue'
import ListComponent from './components/ListComponent.vue'

export default {
  components: { ActionsComponent, FilterComponent, ListComponent },
  data: (instance) => ({
    reloadData: false,
    filtros: {
      dataInicio: instance.filtroValue(instance.$route.query.dataInicio, instance.$date.getDateNowIso(), 'date'),
      dataFim: instance.filtroValue(instance.$route.query.dataFim, instance.$date.getDateNowIso(), 'date'),
      empresaId: instance.filtroValue(instance.$route.query.empresaId, '', 'int'),
      producaoId: instance.filtroValue(instance.$route.query.producaoId, '', 'int'),
      numeroSF: instance.filtroValue(instance.$route.query.bemPatrimonial, '', 'int'),
      status: instance.filtroValue(instance.$route.query.bemPatrimonial, '', 'int'),
      origemId: instance.filtroValue(instance.$route.query.bemPatrimonial, '', 'int'),
      destinoId: instance.filtroValue(instance.$route.query.bemPatrimonial, '', 'int'),
    },
    headersOptions: [
      { text: '', value: 'actions', align: 'center', class: 'custom-table--header', obrigatorio: true, width: 0 },
      { value: 'data-table-expand', align: 'right p-0', class: 'sticky-header custom-table--header', total: 'title' },
      { text: 'Codigo SF', value: 'id', class: 'custom-table--header', obrigatorio: true },
      { text: 'Empresa', value: 'empresa', class: 'sticky-header custom-table--header', obrigatorio: true },
      { text: 'Produção', value: 'producao', class: 'sticky-header custom-table--header', obrigatorio: true },
      { text: 'Criador', value: 'criador', class: 'custom-table--header', obrigatorio: true },
      { text: 'Solicitante', value: 'solicitante', class: 'custom-table--header', ativado: true },
      { text: 'Analista', value: 'analista', class: 'custom-table--header', obrigatorio: true },
      { text: 'Aprovador', value: 'aprovador', class: 'custom-table--header', obrigatorio: true },
      { text: 'Origem', value: 'origem', class: 'custom-table--header', obrigatorio: true },
      { text: 'Destino', value: 'destino', class: 'custom-table--header', obrigatorio: true },
      { text: 'Status', value: 'status', class: 'custom-table--header', obrigatorio: true },
      { text: 'Tipo Produto', value: 'tipo_produto', class: 'custom-table--header', ativado: true },
      { text: 'Altura', value: 'altura', class: 'custom-table--header', ativado: true },
      { text: 'Largura', value: 'largura', class: 'custom-table--header', ativado: true },
      { text: 'Comprimento', value: 'comprimento', class: 'custom-table--header', ativado: true },
      { text: 'Unidade de Medida', value: 'unidade_medida', class: 'custom-table--header', ativado: true },
      { text: 'Numero NF', value: 'numero_nf', class: 'custom-table--header', ativado: true },
      { text: 'Valor NF', value: 'valor_nf', class: 'custom-table--header', ativado: true },
      { text: 'Ordem Compra', value: 'ordem_compra', class: 'custom-table--header', ativado: true },
      { text: 'Veiculo Exigido', value: 'tipo_veiculo_exigido', class: 'custom-table--header', ativado: true },
      { text: 'Veiculo Frete', value: 'tipo_veiculo_frete', class: 'custom-table--header', ativado: true },
      { text: 'Custo pela empresa', value: 'custo_empresa', class: 'custom-table--header', ativado: true },
      { text: 'Custo pela produção', value: 'custo_producao', class: 'custom-table--header', ativado: true },
      { text: 'Seguro', value: 'seguro', class: 'custom-table--header', ativado: true },
      { text: 'Valor Frete', value: 'valor_frete', class: 'custom-table--header', ativado: true },
      { text: 'Data Maxima Entrega', value: 'data_maxima_entrega', class: 'custom-table--header', ativado: true },
      { text: 'Data Prevista Entrega', value: 'data_previsao_entrega', class: 'custom-table--header', ativado: true },
    ],
    headers: [],
  }),
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Fretes', route: 'fretes' },
      { title: 'Listagem de Solicitações' },
    ])
  },
  methods: {
    filtroValue (value, valorPadrao, type) {
      if (value === undefined || value === null || value === '') {
        return valorPadrao
      }

      if (type === 'int') {
        return parseInt(value)
      }

      if (type === 'float') {
        return parseFloat(value)
      }

      if (type === 'array') {
        return [].concat(value)
      }

      return value
    },
  },
}
</script>

<style scoped>

</style>
