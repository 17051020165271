import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"800"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('custom-snackbar',{ref:"snackbar"}),(_vm.model)?_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[(!_vm.item.contratacao_direta)?_c('span',{staticClass:"headline"},[_vm._v("Deseja assumir a análise dessa solicitação de frete?")]):_vm._e(),(_vm.item.contratacao_direta)?_c('span',{staticClass:"headline"},[_vm._v("Deseja assumir a contratação dessa solicitação de frete?")]):_vm._e()]),_c(VForm,{directives:[{name:"show",rawName:"v-show",value:(_vm.showForm),expression:"showForm"}],ref:"form",attrs:{"lazy-validation":""}},[_c(VCardText,[_c(VContainer,[_vm._v(" Esta ação não pode ser desfeita. ")])],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"red darken-1"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"text":"","color":"blue darken-1"},on:{"click":_vm.assumir}},[_vm._v(" Confirmar ")])],1)],1),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!_vm.showForm),expression:"!showForm"}]},[_c('div',{staticClass:"text-center p-5"},[_c('h4',{staticClass:"mb-4"},[_vm._v(" Aguarde... ")]),_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary","size":100,"width":2}})],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }