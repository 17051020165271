<template>
  <div>
    <v-form ref="form">
      <FiltroMobile :has-change="change" @search="seachFilter">
        <v-col class="pb-0 col-12 col-md-2 col-lg-2">
          <DatePicker label="Data Início" v-model="dataInicio"  @input="change = true"/>
        </v-col>
        <v-col class="pb-0 col-12 col-md-2 col-lg-2">
          <DatePicker label="Data Fim" v-model="dataFim" @input="change = true"/>
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <EmpresasSelect
              v-model="empresaId"
              @change="change = true"
          />
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <ProducoesSelect
              v-model="producaoId"
              :empresa="empresaId"
              :disabled="!empresaId"
              @change="change = true"
          />
        </v-col>

        <v-col class="pb-0 col-12 col-md-6 col-lg-2">
          <v-select
              dense
              outlined
              label="Situação"
              v-model="status"
              :items="statusOptionsFilter"
              @change="change = true"
          />
        </v-col>

        <v-col class="pb-0 col-12 col-md-6 col-lg-2">
          <v-text-field
              dense
              outlined
              label="Ordem de Compra"
              v-model="numeroSF"
              @change="change = true"
          />
        </v-col>
      </FiltroMobile>
    </v-form>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
    },
  },
  data: () => {
    return {
      change: false,
      dataInicio: '',
      dataFim: '',
      empresaId: '',
      producaoId: '',
      numeroSF: '',
      status: '',
      origemId: '',
      destinoId: '',
      statusOptionsFilter: [
        { value: '', text: 'Todas' },
        { value: 0, text: 'Solicitado' },
        { value: 1, text: 'Em Cotação' },
        { value: 2, text: 'Cotado' },
        { value: 3, text: 'Aprovado' },
        { value: 4, text: 'Reprovado' },
        { value: 5, text: 'Contratado' },
        { value: 6, text: 'Frete Fornecedor' },
      ],
    }
  },
  mounted () {
    this.dataInicio = this.value?.dataInicio
    this.dataFim = this.value?.dataFim
    this.empresaId = this.value?.empresaId
    this.producaoId = this.value?.producaoId
    this.numeroSF = this.value?.numeroSF
    this.status = this.value?.status
    this.origemId = this.value?.origemId
    this.destinoId = this.value?.destinoId
  },
  watch: {
    value (val) {
      this.dataInicio = val?.dataInicio
      this.dataFim = val?.dataFim
      this.empresaId = val?.empresaId
      this.producaoId = val?.producaoId
      this.numeroSF = val?.numeroSF
      this.status = val?.status
      this.origemId = val?.origemId
      this.destinoId = val?.destinoId
    },
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    seachFilter () {
      if (!this.$refs.form.validate()) {
        return false
      }

      this.model = {
        dataInicio: this.dataInicio,
        dataFim: this.dataFim,
        empresaId: this.empresaId,
        producaoId: this.producaoId,
        numeroSF: this.numeroSF,
        status: this.status,
        origemId: this.origemId,
        destinoId: this.destinoId,
      }

      this.change = false
    },
  },
}
</script>

<style scoped>

</style>
