<template>
  <v-card class="mb-5">
    <v-card-text class="py-0 px-0 px-md-4 py-md-4">
      <v-data-table
          ref="tabela"
          dense
          single-expand
          item-key="codigo_sf"
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          :expanded.sync="expanded"
          :headers="headers"
          :items="data"
          :loading="loading"
          :no-data-text="noData"
          :items-per-page.sync="perPage"
          :server-items-length="totalItems"
          :page.sync="page"
          :options.sync="options"
          :footer-props="$dataTableConfig.getFooterProps()"
          :loading-text="$dataTableConfig.getLoadText()"
          :mobile-breakpoint="0"
          @update:page="getData"
          @update:items-per-page="getData"
          @update:sort-by="getData"
          @update:sort-desc="getData"
        >
          <template v-slot:progress>
            <v-progress-linear
              absolute
              indeterminate
              color="green"
            />
          </template>
          <template v-slot:[`item.origem`]="{ item }">
            <span>{{ item.origem.descricao }}</span>
          </template>
          <template v-slot:[`item.destino`]="{ item }">
            <span>{{ item.destino.descricao }}</span>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <span>{{ item.codigo_sf }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu
                open-on-hover
                offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    :disabled="item.status_code >= 5"
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list class="py-0" dense>
                <v-list-item
                  :disabled="!(can('fretes.solicitacao.assumir.analise') && item.status_code === 0)"
                  dense
                  @click="assumirAnalise(item)"
                >
                  <v-list-item-icon class="mx-0">
                    <v-icon
                        :disabled="!(can('fretes.solicitacao.assumir.analise') && item.status_code === 0)"
                        small
                    >
                      mdi-tray-arrow-down
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Assumir Cotação</v-list-item-title>
                </v-list-item>
                <v-divider class="my-0" />
                <v-list-item
                  :disabled="!(can('fretes.solicitacao.atualizar.analise') && item.status_code === 1)"
                  dense
                  @click="atualizarAnalise(item)"
                >
                  <v-list-item-icon class="mx-0">
                    <v-icon
                        :disabled="!(can('fretes.solicitacao.atualizar.analise') && item.status_code === 1)"
                        small
                    >
                      mdi-pencil
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Preencher Cotação</v-list-item-title>
                </v-list-item>
                <v-divider class="my-0" />
                <v-list-item
                  :disabled="!(can('fretes.solicitacao.aprovar') && item.status_code === 2)"
                  dense
                  @click="aprovarSolicitacao(item)"
                >
                  <v-list-item-icon class="mx-0">
                    <v-icon
                        :disabled="!(can('fretes.solicitacao.aprovar') && item.status_code === 2)"
                        small
                    >
                      mdi-check
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Solicitar Aprovação</v-list-item-title>
                </v-list-item>
                <v-list-item
                    :disabled="!(can('fretes.solicitacao.reprovar') && item.status_code === 2)"
                    dense
                    @click="reprovarSolicitacao(item)"
                >
                  <v-list-item-icon class="mx-0">
                    <v-icon
                        :disabled="!(can('fretes.solicitacao.reprovar') && item.status_code === 2)"
                        small
                    >
                      mdi-close
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Cancelar Cotação</v-list-item-title>
                </v-list-item>
                <v-divider class="my-0" />
                <v-list-item
                    :disabled="!(can('fretes.solicitacao.enviar.aprovacao') && item.status_code === 3)"
                    dense
                    @click="confirmarContratacao(item)"
                >
                  <v-list-item-icon class="mx-0">
                    <v-icon
                        :disabled="!(can('fretes.solicitacao.enviar.aprovacao') && item.status_code === 3)"
                        small
                    >
                      mdi-check-all
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Aprovar Contratação</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:no-data>
            <v-spacer />
            <div>
              <v-row class="justify-center mt-1">
                <v-col class="col-lg-6">
                  <p>Não há dados para serem exibidos</p>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:[`item.tipo_veiculo_exigido`]="{ item }">
            <span>{{ item.nome_tipo_veiculo_exigido }}</span>
          </template>
          <template v-slot:[`item.tipo_veiculo_frete`]="{ item }">
            <span>{{ item.nome_tipo_veiculo_frete }}</span>
          </template>
          <template v-slot:[`item.producao`]="{ item }">
            <span style="text-wrap: nowrap;">{{ item.producao }}</span>
          </template>
          <template v-slot:[`item.empresa`]="{ item }">
            <span style="text-wrap: nowrap;">{{ item.empresa }}</span>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <custom-label
                style="width: 100px; text-wrap: nowrap;"
                :class="getColor(item)"
                :text="item.status"
            />
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <ViewComponents :item="item"></ViewComponents>
            </td>
          </template>
        </v-data-table>
    </v-card-text>
    <AssumeAnaliseDialog v-model="assumirAnaliseDialog" :item="itemSelecionado" @input="getData"></AssumeAnaliseDialog>
    <AtualizaAnaliseDialog v-model="atualizarAnaliseDialog" :item="itemSelecionado" @input="getData"></AtualizaAnaliseDialog>
    <ConfirmarContratacaoDialog v-model="confirmarContratacaoDialog" :item="itemSelecionado" @input="getData"></ConfirmarContratacaoDialog>
    <AprovaSolicitacaoDialog v-model="aprovarSolicitacaoDialog" :item="itemSelecionado" @input="getData"></AprovaSolicitacaoDialog>
    <ReprovaSolicitacaoDialog v-model="reprovarSolicitacaoDialog" :item="itemSelecionado" @input="getData"></ReprovaSolicitacaoDialog>
  </v-card>
</template>

<script>

import ApiService from '@/core/services/api.service'
import { can } from '@/core/services/storage.service'

import AssumeAnaliseDialog from './AssumeAnaliseDialog.vue'
import AtualizaAnaliseDialog from './AtualizaAnaliseDialog.vue'
import ConfirmarContratacaoDialog from './ConfirmarContratacaoDialog.vue'
import AprovaSolicitacaoDialog from './AprovaSolicitacaoDialog.vue'
import ReprovaSolicitacaoDialog from './ReprovaSolicitacaoDialog.vue'
import ViewComponents from './ViewComponents.vue'

export default {
  components: {
    ViewComponents,
    AssumeAnaliseDialog,
    AtualizaAnaliseDialog,
    ConfirmarContratacaoDialog,
    AprovaSolicitacaoDialog,
    ReprovaSolicitacaoDialog,
  },
  props: {
    headers: {
      type: Object,
    },
    filtros: {
      type: Object,
    },
    reloadData: {
      type: Boolean,
      default: false,
    },
  },
  data: (instance) => ({
    // General
    loading: false,
    assumirAnaliseDialog: false,
    atualizarAnaliseDialog: false,
    confirmarContratacaoDialog: false,
    aprovarSolicitacaoDialog: false,
    reprovarSolicitacaoDialog: false,
    mostrarRejeitadaDialog: false,
    noData: 'Nenhuma informação encontrada',
    // Form Data
    data: [],
    // Expand Data Table
    expanded: [],
    itemSelecionado: null,
    perPage: instance.$dataTableConfig.getItemsPerPage(),
    page: 1,
    totalItems: 0,
    sortBy: 'created_at',
    sortDesc: true,
    options: {},
  }),

  watch: {
    filtros () {
      this.$router.push({ query: this.filtros, silent: true }).catch((e) => e)
      this.getData()
    },
    reloadData (val) {
      if (val) {
        this.getData()
        this.reloadData = false
      }

      this.$emit('update:reloadData', val)
    },
  },

  mounted () {
    this.getData()

    this.$refs.tabela.$el.querySelector('thead').addEventListener('wheel', (evt) => {
      evt.preventDefault()
      this.$refs.tabela.$el.querySelector('.v-data-table__wrapper').scrollLeft += evt.deltaY
    })
  },

  methods: {
    can,
    getData () {
      this.itemSelecionado = null

      if (!this.filtros.dataInicio || !this.filtros.dataFim) {
        return
      }

      this.loading = true
      this.expanded = []
      /**
       * Configuração da página e ordem da tabela
       */
      this.filtros.pageSize = this.perPage
      this.filtros.orderBy = this.sortBy
      this.filtros.orderType = this.sortDesc ? 'DESC' : 'ASC'
      this.filtros.page = this.page

      ApiService
        .cancel('solicitacao-frete')
        .get(
          '/fretes/solicitacao',
          '?' + this.$stringFormat.queryParamsRecursive(this.filtros),
        )
        .then((res) => {
          this.data = res.data.data
          this.totalItems = res.data.meta.total

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    assumirAnalise (item) {
      this.assumirAnaliseDialog = true
      this.itemSelecionado = item
    },
    atualizarAnalise (item) {
      this.atualizarAnaliseDialog = true
      this.itemSelecionado = item
    },
    confirmarContratacao (item) {
      this.confirmarContratacaoDialog = true
      this.itemSelecionado = item
    },
    aprovarSolicitacao (item) {
      this.aprovarSolicitacaoDialog = true
      this.itemSelecionado = item
    },
    reprovarSolicitacao (item) {
      this.reprovarSolicitacaoDialog = true
      this.itemSelecionado = item
    },
    validDate (value) {
      if (value == null) {
        return '-'
      }

      return value
    },
    getColor (item) {
      if (item.contratacao_direta) {
        return 'bg-contratacao-direta'
      }

      return ([
        'bg-solicitada',
        'bg-em-analise',
        'bg-analisada',
        'bg-aprovada',
        'bg-reprovada',
        'bg-contratada',
        'bg-fornecedor',
      ])[item.status_code]
    },

    getText (aplicacaoParcial) {
      return aplicacaoParcial === 0 || aplicacaoParcial === false ? 'Não' : 'Sim'
    },
  },
}
</script>

<style>
.bg-solicitada {
  background-color: #FDD01C;
  color: #000;
}
.bg-em-analise {
  background-color: #1F3853;
  color: #fff;
}
.bg-analisada {
  background-color: #0090D9;
  color: #fff;
}
.bg-aprovada {
  background-color: #0AA699;
  color: #fff;
}
.bg-reprovada {
  background-color: #E36968;
  color: #fff;
}
.bg-contratada {
  background-color: #0aa617;
  color: #fff;
}
.bg-fornecedor {
  background-color: #0aa617;
  color: #ffffff;
}
.bg-contratacao-direta {
  background-color: purple;
  color: #fff;
}
.label.label-lg.label-inline {
  width: 100%;
}
</style>
