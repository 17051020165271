<template>
  <div>
    <v-toolbar flat class="d-flex flex-row-reverse pb-5" color="rgba(0,0,0,0)">
      <v-btn
          color="primary"
          dark
          class="mr-3"
          title="Remover Filtros"
          @click="removeFiltros"
      >
        <v-icon>mdi-filter-remove</v-icon>
      </v-btn>
      <HeaderControl v-model="model" :headers="headersOptions" :local-storage-save-name="localStorageSaveName"></HeaderControl>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              class="mr-2"
              dark
              v-bind="attrs"
              v-on="on"
          >
            <v-icon dark>
              mdi-tray-arrow-down
            </v-icon>
          </v-btn>
        </template>
        <v-list class="py-0" dense>
          <v-list-item dense @click="downloadReport('excel')">
            <v-list-item-icon class="mx-0"><v-icon small>mdi-microsoft-excel</v-icon></v-list-item-icon>
            <v-list-item-title>Excel</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
          color="primary"
          @click="novoLancamento"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-toolbar>
    <custom-snackbar ref="snackbar" />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  props: {
    value: {
      type: Object,
    },
    filtros: {
      type: Object,
    },
    headersOptions: {
      type: Array,
    },
    urlPdf: {
      type: String,
      default: '',
    },
    relatorioExcel: {
      type: String,
      default: '',
    },
    localStorageSaveName: {
      type: String,
    },
    excelPdf: {
      type: Boolean,
      default: false,
    },
  },

  data: () => {
    return {
    }
  },

  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    removeFiltros () {
      this.$router.push({ query: null })
      window.location.reload()
    },

    novoLancamento () {
      this.$router.push({ name: 'adicona-solicitacao-frete' })
    },

    downloadReport (type) {
      ApiService.downloadReport(type, 'solicitacao_frete', this.filtros, this.$refs.snackbar)
    },
  },
}
</script>

<style scoped>

</style>
