<template>
  <v-container>
    <custom-snackbar
        ref="snackbar"
    />
    <div style="max-width: 600px;">
      <strong>Observação:</strong>
      <p>{{ item.observacao }}</p>
      <hr>
    </div>
  </v-container>
</template>
<script>

export default {
  props: {
    item: {
      type: Object,
    },
  },
}
</script>
