<template>
  <v-dialog v-model="model" transition="dialog-bottom-transition" max-width="800">
    <custom-snackbar ref="snackbar" />
    <v-card v-if="model">
      <v-toolbar dark color="primary">
        <span class="headline" v-if="!item.contratacao_direta">Adicione informações da cotação para a analise</span>
        <span class="headline" v-if="item.contratacao_direta">Adicione informações da contratação para a solicitação</span>
      </v-toolbar>

      <v-form ref="form" v-show="showForm" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <DatePicker
                  label="Data de Previsão da Entrega"
                  v-model="form.dataPrevisaoEntrega"
                  :rules="[(v) => !!v || 'Obrigatório']"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  outlined
                  reverse
                  type="number"
                  min="0"
                  step="0.1"
                  v-model="form.valorFrete"
                  label="Valor Frete"
                  :rules="[(v) => !!v || 'Obrigatório']"
                />
              </v-col>
              <v-col class="pb-0 col-12">
                <v-select
                  dense
                  outlined
                  label="Tipo do Veiculo de Frete"
                  v-model="form.tipoVeiculoFrete"
                  :items="tipoVeiculoOptions"
                  :rules="[
                      (v) => !item.tipo_veiculo_exigido || v===item.tipo_veiculo_exigido || `O frete exige a utilização do ${item.nome_tipo_veiculo_exigido}`,
                      (v) => v!=='' || 'Obrigatório',
                  ]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn text color="blue darken-1" @click="close">
            Cancelar
          </v-btn>

          <v-btn text color="blue darken-1" @click="salvar">
            <span v-if="!item.contratacao_direta">Enviar para Aprovação</span>
            <span v-if="item.contratacao_direta">Confirmar Contratação</span>
          </v-btn>
        </v-card-actions>
      </v-form>

      <v-card-text v-show="!showForm">
        <div class="text-center p-5">
          <h4 class="mb-4">
            Aguarde...
          </h4>
          <v-progress-circular
            indeterminate
            color="primary"
            :size="100"
            :width="2"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data: (instance) => ({
    showForm: true,
    tipoVeiculoOptions: [
      { value: '', text: 'Selecione' },
      { value: 0, text: 'Vam/Vuc' },
      { value: 1, text: 'Toco' },
      { value: 2, text: 'Truck' },
      { value: 3, text: 'Carreta' },
    ],
    form: {
      dataPrevisaoEntrega: '',
      valorFrete: '',
      tipoVeiculoFrete: '',
    },
  }),
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    close () {
      this.model = false

      this.form = {
        dataPrevisaoEntrega: '',
        valorFrete: '',
        tipoVeiculoFrete: '',
      }
    },
    salvar () {
      if (!this.$refs.form.validate()) {
        return
      }

      this.showForm = false

      ApiService
        .put('/fretes/solicitacao/' + this.item.codigo_sf + '/analise', this.form)
        .then((response) => {
          this.model = false
          this.showForm = true

          this.form = {
            dataPrevisaoEntrega: '',
            valorFrete: '',
            tipoVeiculoFrete: '',
          }
        })
        .catch((error) => {
          this.$refs.snackbar.show('Problema ao excluir registro', error.message, 'danger', 3000)
          this.showForm = true

          this.form = {
            dataPrevisaoEntrega: '',
            valorFrete: '',
            tipoVeiculoFrete: '',
          }
        })
    },
  },
}
</script>
