import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"transition":"dialog-bottom-transition","max-width":"800"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('custom-snackbar',{ref:"snackbar"}),(_vm.model)?_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[(!_vm.item.contratacao_direta)?_c('span',{staticClass:"headline"},[_vm._v("Adicione informações da cotação para a analise")]):_vm._e(),(_vm.item.contratacao_direta)?_c('span',{staticClass:"headline"},[_vm._v("Adicione informações da contratação para a solicitação")]):_vm._e()]),_c(VForm,{directives:[{name:"show",rawName:"v-show",value:(_vm.showForm),expression:"showForm"}],ref:"form",attrs:{"lazy-validation":""}},[_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('DatePicker',{attrs:{"label":"Data de Previsão da Entrega","rules":[function (v) { return !!v || 'Obrigatório'; }]},model:{value:(_vm.form.dataPrevisaoEntrega),callback:function ($$v) {_vm.$set(_vm.form, "dataPrevisaoEntrega", $$v)},expression:"form.dataPrevisaoEntrega"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VTextField,{attrs:{"dense":"","outlined":"","reverse":"","type":"number","min":"0","step":"0.1","label":"Valor Frete","rules":[function (v) { return !!v || 'Obrigatório'; }]},model:{value:(_vm.form.valorFrete),callback:function ($$v) {_vm.$set(_vm.form, "valorFrete", $$v)},expression:"form.valorFrete"}})],1),_c(VCol,{staticClass:"pb-0 col-12"},[_c(VSelect,{attrs:{"dense":"","outlined":"","label":"Tipo do Veiculo de Frete","items":_vm.tipoVeiculoOptions,"rules":[
                    function (v) { return !_vm.item.tipo_veiculo_exigido || v===_vm.item.tipo_veiculo_exigido || ("O frete exige a utilização do " + (_vm.item.nome_tipo_veiculo_exigido)); },
                    function (v) { return v!=='' || 'Obrigatório'; } ]},model:{value:(_vm.form.tipoVeiculoFrete),callback:function ($$v) {_vm.$set(_vm.form, "tipoVeiculoFrete", $$v)},expression:"form.tipoVeiculoFrete"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"blue darken-1"},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"text":"","color":"blue darken-1"},on:{"click":_vm.salvar}},[(!_vm.item.contratacao_direta)?_c('span',[_vm._v("Enviar para Aprovação")]):_vm._e(),(_vm.item.contratacao_direta)?_c('span',[_vm._v("Confirmar Contratação")]):_vm._e()])],1)],1),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(!_vm.showForm),expression:"!showForm"}]},[_c('div',{staticClass:"text-center p-5"},[_c('h4',{staticClass:"mb-4"},[_vm._v(" Aguarde... ")]),_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary","size":100,"width":2}})],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }