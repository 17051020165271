<template>
  <v-dialog v-model="model" transition="dialog-bottom-transition" max-width="800">
    <custom-snackbar ref="snackbar" />
    <v-card v-if="model">
      <v-toolbar dark color="primary">
        <span class="headline" v-if="!item.contratacao_direta">Deseja assumir a análise dessa solicitação de frete?</span>
        <span class="headline" v-if="item.contratacao_direta">Deseja assumir a contratação dessa solicitação de frete?</span>
      </v-toolbar>

      <v-form ref="form" lazy-validation v-show="showForm">
        <v-card-text>
          <v-container>
            Esta ação não pode ser desfeita.
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn text color="red darken-1" @click="close">
            Cancelar
          </v-btn>

          <v-btn text color="blue darken-1" @click="assumir">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-form>

      <v-card-text v-show="!showForm">
        <div class="text-center p-5">
          <h4 class="mb-4">
            Aguarde...
          </h4>
          <v-progress-circular
              indeterminate
              color="primary"
              :size="100"
              :width="2"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  data: (instance) => ({
    showForm: true,
  }),
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    close () {
      this.model = false
    },
    assumir () {
      this.showForm = false

      ApiService
        .post('/fretes/solicitacao/' + this.item.codigo_sf + '/analise')
        .then((response) => {
          this.model = false
          this.showForm = true
        })
        .catch((error) => {
          this.$refs.snackbar.show('Problema ao assumir a análise:', error.message, 'danger', 3000)
          this.showForm = true
        })
    },
  },
}
</script>
